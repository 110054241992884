.mco-singleteaser-03__title {
	margin-bottom: 24px !important;
	padding-bottom: 0 !important;
	position: relative;
}
.mco-singleteaser-03__title::after {
	background: linear-gradient(270deg, #c65470 0%, #548eb5 100%) !important;
	height: 2px !important;
	left: -56px !important;
	top: 16px !important;
	width: 32px !important;
}
.mco-singleteaser-03__title strong {
	color: var(--mco-palette-primary-main);
	font-weight: 400 !important;
}
.mco-singleteaser-03__image {
	background: var(--mco-palette-background-light);
}
.mco-singleteaser-03__content {
	padding: 40px 40px 0 56px !important;
	position: relative;
}
.mco-singleteaser-03__button {
	justify-content: flex-end !important;
	margin-top: 40px !important;
}

@media (min-width: 600px) {
	.mco-singleteaser-03__image {
		flex: unset !important;
		flex-grow: 0;
		max-width: 50%;
		flex-basis: 50%;
	}
	.mco-singleteaser-03__content {
		flex: unset !important;
		flex-grow: 0;
		max-width: 50%;
		flex-basis: 50%;
		min-height: unset !important;
		padding: 24px 16px 0 48px !important;
	}
	.mco-singleteaser-03__title::after {
		left: -48px !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__title::after {
		left: auto !important;
		right: -48px !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__button {
		justify-content: flex-start !important;
		margin-left: -5px;
	}
}

@media (min-width: 960px) {
	.mco-singleteaser-03__content {
		padding: 32px 24px 32px 80px !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__content {
		padding: 32px 80px 32px 24px !important;
	}
	.mco-singleteaser-03__title::after {
		left: -80px !important;
		top: 24px !important;
		width: 56px !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__title::after {
		right: -80px !important;
	}
	.mco-singleteaser-03 .mco-singleteaser-03__image,
	.mco-singleteaser-03 .mco-singleteaser-03__image img {
		border-radius: 0 4px 4px 0;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__image,
	.mco-singleteaser-03--right .mco-singleteaser-03__image img {
		border-radius: 4px 0px 0px 4px;
	}
}

@media (min-width: 1280px) {
	.mco-singleteaser-03__content {
		padding: 32px 80px !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__content {
		padding: 32px 80px !important;
	}
}

@media (min-width: 1440px) {
	.mco-singleteaser-03 .mco-singleteaser-03__image,
	.mco-singleteaser-03 .mco-singleteaser-03__image img {
		border-radius: 4px !important;
	}
}

@media (min-width: 1441px) {
	.mco-view-component-container-basic-singleteaser {
		max-width: 1280px !important;
	}
	.mco-singleteaser-03__content {
		padding: 32px 0 32px 80px !important;
	}
	.mco-singleteaser-03--right .mco-singleteaser-03__content {
		padding: 32px 80px 32px 0 !important;
	}
}
