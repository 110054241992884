.mco-dropdown-01 {
	border-bottom: 1px solid #cccccc !important;
	border-top: unset !important;
}
.mco-dropdown-01__summary {
	background: none !important;
	min-height: 36px !important;
	padding: 0 4px !important;
}
.mco-dropdown-01__headline {
	color: var(--mco-palette-text-primary) !important;
	font-family: "Open Sans" !important;
	font-size: 18px !important;
	position: relative;
}
.mco-dropdown-01 .MuiAccordionSummary-content.Mui-expanded,
.mco-dropdown-01 .MuiAccordionSummary-content {
	margin: 8px 0 !important;
}
.mco-dropdown-01__details {
	padding: 8px 16px 16px !important;
}

@media (min-width: 960px) {
	.mco-dropdown-01__details {
		padding: 16px 48px 24px !important;
	}
	.mco-dropdown-01__details .mco-view-component-container {
		padding: 0 !important;
	}
	.mco-dropdown-01__headline {
		padding-left: 32px;
	}
	.mco-dropdown-01__headline::before {
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03Ljk2OTY3IDQuMDk0NjdDOC4yNjI1NiAzLjgwMTc4IDguNzM3NDQgMy44MDE3OCA5LjAzMDMzIDQuMDk0NjdMMTYuMjgwMyAxMS4zNDQ3QzE2LjU3MzIgMTEuNjM3NiAxNi41NzMyIDEyLjExMjQgMTYuMjgwMyAxMi40MDUzTDkuMDMwMzMgMTkuNjU1M0M4LjczNzQ0IDE5Ljk0ODIgOC4yNjI1NiAxOS45NDgyIDcuOTY5NjcgMTkuNjU1M0M3LjY3Njc4IDE5LjM2MjQgNy42NzY3OCAxOC44ODc2IDcuOTY5NjcgMTguNTk0N0wxNC42ODkzIDExLjg3NUw3Ljk2OTY3IDUuMTU1MzNDNy42NzY3OCA0Ljg2MjQ0IDcuNjc2NzggNC4zODc1NiA3Ljk2OTY3IDQuMDk0NjdaIiBmaWxsPSIjQUEwMDI5Ii8+Cjwvc3ZnPgo=");
		content: "";
		height: 24px;
		left: 0;
		margin-top: -12px;
		position: absolute;
		top: 50%;
		width: 24px;
	}
}
