@import url("./googlefonts.css");
@import url("./custom.css");
@import url("./collaborates.css");
@import url("./elements/list.css");
@import url("./elements/imagetext.css");
@import url("./elements/singleimage.css");
@import url("./elements/gallery.css");
@import url("./elements/headline.css");
@import url("./elements/table.css");
@import url("./elements/dropdown.css");
@import url("./elements/form.css");
@import url("./elements/references.css");
@import url("./elements/singleteaser.css");
@import url("./elements/searchresults.css");
@import url("./elements/blog-share.css");
@import url("./elements/blog-teaser.css");
@import url("./extensions/store-locator.css");

html {
	font-size: 100%;
	min-height: calc(100% + env(safe-area-inset-top));
}
body {
	margin: 0;
}
a {
	color: var(--mco-palette-primary-main);
	text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
#root {
	padding: 0;
	position: relative;
}
.mco-content .mco-container:first-child {
	margin-top: 0;
}
*:not(.no-custom-scrollbar) {
	scrollbar-color: var(--mco-palette-text-primary) transparent;
	scrollbar-width: thin;
}
*:not(.no-custom-scrollbar)::-webkit-scrollbar {
	width: 10px;
}
*:not(.no-custom-scrollbar)::-webkit-scrollbar-track {
	background: transparent;
}
*:not(.no-custom-scrollbar)::-webkit-scrollbar-thumb {
	background-color: var(--mco-palette-text-primary);
	border: 0 solid transparent;
	border-radius: 0;
}
.MuiSkeleton-text {
	transform: none !important;
}
.mco-collapsing + .mco-collapsing {
	margin-top: -80px;
}
.mco-view-component-container-basic-download-group {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.mco-view-component-container-basic-download-group .mco-view-component-container {
	padding-left: 0;
	padding-right: 0;
}
::-moz-selection {
	color: #ffffff;
	background: var(--mco-palette-primary-main);
}
::selection {
	color: #ffffff;
	background: var(--mco-palette-primary-main);
}
