.mco-form {
	background: var(--mco-palette-background-light);
	box-shadow: 0 0 0 100vmax var(--mco-palette-background-light);
	clip-path: inset(0 -100vmax);
	margin: 0 auto;
	padding: 80px 0;
}
.mco-form .MuiInputBase-formControl {
	background: #ffffff;
}
.mco-form .mco-form-button-wrapper {
	display: flex;
	justify-content: flex-end;
	margin-top: 24px;
}
