.mco-imagetext-03::after {
	background: none !important;
}
.mco-imagetext-03__title {
	margin-bottom: 24px !important;
	padding-bottom: 0 !important;
}
.mco-imagetext-03__title::after {
	background: linear-gradient(270deg, #c65470 0%, #548eb5 100%) !important;
	height: 2px !important;
	left: -56px !important;
	top: 16px !important;
	width: 32px !important;
}
.mco-imagetext-03__title strong {
	color: var(--mco-palette-primary-main) !important;
	font-weight: 400 !important;
}
.mco-imagetext-03__content {
	padding: 40px 40px 0 56px !important;
}

@media (min-width: 600px) {
	.mco-imagetext-03 {
		padding: 0 !important;
	}
	.mco-imagetext-03__content {
		min-height: unset !important;
		padding: 24px 16px 0 48px !important;
	}
	.mco-imagetext-03--right .mco-imagetext-03__content {
		padding: 24px 48px 0 16px !important;
	}
	.mco-imagetext-03 .mco-imagetext-03__image img {
		border-radius: 0 4px 4px 0;
	}
	.mco-imagetext-03--right .mco-imagetext-03__image img {
		border-radius: 4px 0px 0px 4px;
	}
	.mco-imagetext-03__title::after {
		left: -48px !important;
	}
	.mco-imagetext-03--right .mco-imagetext-03__title::after {
		left: auto !important;
		right: -48px !important;
	}
}

@media (min-width: 960px) {
	.mco-imagetext-03__content {
		padding: 32px 80px !important;
	}
	.mco-imagetext-03--right .mco-imagetext-03__content {
		padding: 32px 80px !important;
	}
	.mco-imagetext-03__title::after {
		left: -80px !important;
		top: 24px !important;
		width: 56px !important;
	}
	.mco-imagetext-03--right .mco-imagetext-03__title::after {
		right: -80px !important;
	}
	.mco-imagetext-03 .mco-imagetext-03__image img {
		border-radius: 0 4px 4px 0;
	}
	.mco-imagetext-03--right .mco-imagetext-03__image img {
		border-radius: 4px 0px 0px 4px;
	}
}

@media (min-width: 1440px) {
	.mco-imagetext-03 .mco-imagetext-03__image img {
		border-radius: 4px !important;
	}
}
