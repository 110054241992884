/* iFrame */

body.iframe {
	overflow: hidden !important;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
body.iframe #mco-consent,
body.iframe .mco-overlay:has(*[class^="mco-consent"]) {
	display: none !important;
}

/* Text */

.mco-text-01 button {
	display: flex;
	margin-left: auto;
	margin-right: auto;
}

/* SingleTeaser02 */

.mco-singleteaser-02 {
	padding: 0 24px;
}
.mco-singleteaser-02::after {
	background: var(--mco-palette-background-light) !important;
}
.mco-singleteaser-02 .mco-singleteaser-02__image img {
	border-radius: 8px 8px 0 0;
}

/* Video02 */

.mco-video-02 {
	background: var(--mco-palette-background-light) !important;
}

/* Blog */

/* Blog - Overview */

.mco-blog-overview .mco-blog-list-teaser__list {
	gap: 32px;
	grid-row-gap: 32px;
}
.mco-blog-overview .mco-blog-list-teaser-item {
	box-shadow: 0px 0px 1px rgb(0 0 0 / 12%), 5px 16px 36px rgb(0 0 0 / 8%);
	border-radius: 4px;
	transition: box-shadow 250ms ease;
}
.mco-blog-overview .mco-blog-list-teaser-item__title {
	color: var(--mco-palette-primary-main) !important;
}
.mco-blog-overview .mco-blog-list-teaser-item__info-date {
	background: var(--mco-palette-background-light) !important;
	color: var(--mco-palette-text-primary) !important;
}
.mco-blog-overview .mco-blog-list-teaser-item__info-label {
	background: var(--mco-palette-primary-main) !important;
}
.mco-blog-overview .mco-blog-list-teaser-item__categories {
	color: var(--mco-palette-text-primary) !important;
}
.mco-blog-overview .mco-blog-list-teaser-item__author {
	color: var(--mco-palette-primary-main) !important;
}

/* Blog - Info */

.mco-blog-blogger-info {
	display: flex !important;
	flex-direction: column !important;
	gap: 12px !important;
}
.mco-blog-blogger-info .mco-blog-blogger-info__author-wrapper {
	justify-content: center !important;
}
.mco-blog-blogger-info .mco-blog-blogger-info__author {
	color: var(--mco-palette-primary-main) !important;
	text-decoration: underline !important;
}
.mco-blog-blogger-info .mco-blog-blogger-info__date {
	text-align: center !important;
}
.mco-view-component-container-mco-blog-detail-info .mco-share {
	display: flex !important;
	justify-content: flex-end !important;
}

/* Blog - Tags */

.mco-blog-tags .mco-blog-tags__headline {
	color: var(--mco-palette-text-secondary) !important;
}

/* Suche */

.mco-search-intentions-item__title {
	font-size: 16px !important;
	text-transform: none !important;
}

/* Swiper */

.swiper-pagination-bullet {
	background: var(--mco-palette-primary-dark) !important;
	border-radius: 8px !important;
	height: 2px !important;
	opacity: 0.3 !important;
	transition: opacity 250ms ease, width 250ms ease !important;
	width: 16px !important;
}
.swiper-pagination-bullet-active {
	opacity: 1 !important;
	width: 32px !important;
}

/* Animation */

@keyframes linearBackgroundTop {
	0% {
		background-position: 0% 0;
	}
	50% {
		background-position: 100% 0;
	}
	100% {
		background-position: 0% 0;
	}
}

@keyframes linearBackgroundLeft {
	0% {
		background-position: 0% 0;
	}
	50% {
		background-position: 0 100%;
	}
	100% {
		background-position: 0% 0;
	}
}

@media (max-width: 599px) {
	/* SingleTeaser02 */

	.mco-singleteaser-02 .mco-singleteaser-02__content {
		padding-left: 0;
		padding-right: 0;
	}
}

@media (min-width: 600px) {
	/* SingleTeaser02 */

	.mco-singleteaser-02 {
		padding: 120px 0;
	}
	.mco-singleteaser-02 .mco-singleteaser-02__image img {
		border-radius: 0 8px 8px 0;
	}
	.mco-singleteaser-02--right .mco-singleteaser-02__image img {
		border-radius: 8px 0 0 8px;
	}

	/* Form */

	.mco-form {
		padding: 48px;
	}

	/* Blog */

	/* Blog - Overview */

	.mco-blog-overview .mco-blog-list-teaser-item {
		background: #ffffff;
		box-shadow: 0px 0px 1px rgb(0 0 0 / 12%), 8px 16px 32px rgb(0 0 0 / 8%);
	}
	.mco-blog-overview .mco-blog-list-teaser-item:hover {
		box-shadow: 0px 0px 1px rgb(0 0 0 / 12%), 8px 16px 32px rgb(0 0 0 / 20%);
	}
	.mco-blog-overview .mco-blog-list-teaser-item:not(.mco-blog-list-teaser-item--grid) {
		padding: 16px;
	}
}

@media (min-width: 1872px) {
	/* SingleTeaser02 */

	.mco-singleteaser-02 .mco-singleteaser-02__image img {
		border-radius: 8px;
	}
}
