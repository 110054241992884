.mco-gallery-02-swiper-nav__next,
.mco-gallery-02-swiper-nav__prev {
	background: rgba(0, 0, 0, 0.2) !important;
	border-radius: unset !important;
	height: 48px !important;
	width: 48px !important;
}
.mco-gallery-02-swiper-nav__next svg,
.mco-gallery-02-swiper-nav__prev svg {
	width: 32px !important;
}
.mco-gallery-02 .swiper-button-disabled {
	pointer-events: all !important;
}
.mco-gallery-02-thumb-swiper__item {
	border-radius: 4px !important;
}
.mco-gallery-02-thumb-swiper__item img {
	border-radius: 4px !important;
}
.mco-gallery-02-thumb-swiper__item.swiper-slide-thumb-active {
	outline: 2px solid var(--mco-palette-primary-main) !important;
}

@media (min-width: 960px) {
	.mco-gallery-02 {
		position: relative;
	}
	.mco-gallery-02 .mco-gallery-02-thumb-swiper {
		bottom: 0 !important;
		padding-bottom: 16px !important;
		width: 100%;
		position: absolute !important;
	}
	.mco-gallery-02-swiper-nav__next,
	.mco-gallery-02-swiper-nav__prev {
		height: 64px !important;
		width: 64px !important;
	}
	.mco-gallery-02-swiper-nav__next svg,
	.mco-gallery-02-swiper-nav__prev svg {
		width: 48px !important;
	}
}
